<template>
  <div class="qrcode">
    <h1 class="mb-5">Cetak QR Code</h1>
    <div class="row">
      <!-- Card -->
      <div class="col-md-12">
        <div class="card">
          <h5
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            Daftar QR Code
            <div class="">
              <button
                @click="printUnclaimQrCode"
                class="btn btn-sm btn-dark mx-1"
              >
                <i class="bi bi-printer"></i> Print QR Code
              </button>
              <button
                @click="showFormModal = true"
                class="btn btn-sm btn-primary"
              >
                <i class="bi bi-plus-circle"></i> Generate QR Code
              </button>
            </div>
          </h5>
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-2">
                Status:
                <select
                  class="form-select"
                  aria-label="Status QR Code"
                  v-model="search.status"
                >
                  <option selected value="">Semua</option>
                  <option value="unclaimed">Belum Digunakan</option>
                  <option value="claimed">Sudah Digunakan</option>
                </select>
              </div>
              <div class="col-md-10">
                Filter
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Cari"
                    aria-label="Cari"
                    aria-describedby="button-addon2"
                    v-model="search.code"
                    @keypress.enter="searchData"
                  />
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="searchData"
                  >
                    Cari
                  </button>
                </div>
              </div>
            </div>
            <transition name="fade">
              <div class="text-center" v-if="isLoading">
                <div class="spinner-grow" role="status"></div>
              </div>
              <Table
                v-else
                :headers="headers"
                :items="qrcode"
                :currentUser="currentUser"
                @edit="editItem"
                @assignToMember="assignToMember"
                @assignToMerchandise="assignToMerchandise"
                @unclaimQrCode="unclaimQrCode"
              />
            </transition>
            <Pagination
              v-if="qrcode.length"
              :currentPage="currentPage"
              :totalData="totalData"
              :totalPage="totalPage"
              @nextPage="nextPage"
              @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal tambah/edit -->
    <form-modal
      :showModal="showFormModal"
      :editedIndex="editedIndex"
      :editedId="editedId"
      :editedItem="editedItem"
      @closeModal="closeForm"
      @save="saveForm"
    />

    <!-- Modal Assign to Anggota -->
    <assign-modal
      :showModal="showAssignModal"
      :editedIndex="editedAssignIndex"
      :editedId="editedAssignId"
      :editedItem="editedAssignItem"
      :initialAnggota="anggota"
      @closeModal="closeForm"
      @save="assign"
    />

    <!-- Modal Assign to Merchandise -->
    <assign-merchandise-modal
      :showModal="showAssignMerchandiseModal"
      :editedIndex="editedAssignMerchandiseIndex"
      :editedId="editedAssignMerchandiseId"
      :editedItem="editedAssignMerchandiseItem"
      :initialMerchandise="merchandise"
      @closeModal="closeForm"
      @save="assignMerchandise"
    />
  </div>
</template>

<script>
import { qrCodeService, anggotaService, merchandiseService } from "@/services";
import FormModal from "./components/FormModal.vue";
import AssignModal from "./components/AssignModal.vue";
import AssignMerchandiseModal from "./components/AssignMerchandiseModal.vue";
import Table from "./components/Table.vue";
import Pagination from "./components/Pagination.vue";
import { authenticationService } from "@/services";

export default {
  components: {
    Table,
    FormModal,
    Pagination,
    AssignModal,
    AssignMerchandiseModal,
  },
  data() {
    return {
      currentUser: authenticationService.currentUserValue,
      // Search query
      search: {
        code: "",
        status: "",
      },
      limit: 10,
      sort: "updatedAt",
      order: "desc",

      // Pagination
      totalData: 0,
      currentPage: 1,

      // Loading State
      isLoading: false,

      // Data Array
      headers: ["Kode", "Digunakan Untuk", "Tindakan"],
      qrcode: [],
      anggota: [],
      merchandise: [],

      // Modal state
      showFormModal: false,
      showAssignModal: false,
      showAssignMerchandiseModal: false,

      // Default form state
      editedIndex: -1,
      editedId: 0,
      editedItem: {
        banyaknya: 1,
      },
      defaultItem: {
        banyaknya: 1,
      },

      // Default form assign state
      editedAssignIndex: -1,
      editedAssignId: 0,
      editedAssignItem: {
        anggota_id: "",
      },
      defaultAssignItem: {
        anggota_id: "",
      },

      // Default form assign merchandise state
      editedAssignMerchandiseIndex: -1,
      editedAssignMerchandiseId: 0,
      editedAssignMerchandiseItem: {
        merchandise_id: "",
      },
      defaultAssignMerchandiseItem: {
        merchandise_id: "",
      },
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  methods: {
    // Search & Load
    searchData() {
      this.isLoading = true;
      this.currentPage = 1;
      this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    async loadData(limit, search, page, sort, order) {
      await qrCodeService
        .getAll(limit, search, page, sort, order)
        .then((data) => {
          this.qrcode = data.rows;
          this.totalData = data.count;
          this.currentPage = page;
        });

      this.isLoading = false;
    },

    // Pagination
    previousPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage - 1,
        this.sort,
        this.order
      );
    },
    nextPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage + 1,
        this.sort,
        this.order
      );
    },

    // Modal
    editItem(item) {
      this.editedIndex = this.qrcode.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showFormModal = true;
    },
    async saveForm() {
      // Insert new data
      try {
        await qrCodeService.generate(this.editedItem);
        this.closeForm();
        await this.searchData();
      } catch (error) {
        this.$toast.error(
          (error.response && error.response.data.errors[0].message) ||
            error.message
        );
      }
    },
    closeForm() {
      this.showFormModal = false;
      this.showAssignModal = false;
      this.showAssignMerchandiseModal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    assignToMember(item) {
      this.editedAssignIndex = this.qrcode.indexOf(item);
      this.editedAssignId = item.id;
      this.editedAssignItem = Object.assign({}, item);
      this.showAssignModal = true;
    },
    assignToMerchandise(item) {
      this.editedAssignMerchandiseIndex = this.qrcode.indexOf(item);
      this.editedAssignMerchandiseId = item.id;
      this.editedAssignMerchandiseItem = Object.assign({}, item);
      this.showAssignMerchandiseModal = true;
    },
    async unclaimQrCode(item) {
      try {
        await qrCodeService.unclaimQrCode(item.id);
        this.$toast.success(`QR Code berhasil dilepas.`);
      } catch (error) {
        this.$toast.error(`QR Code Gagal dilepas. Coba lagi nanti.`);
      }
      await this.searchData();
    },
    async assign() {
      await qrCodeService.assignToMember({
        id: this.editedAssignId,
        anggota_id: this.editedAssignItem.anggota_id,
      });
      this.closeForm();
      await this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    async assignMerchandise() {
      await qrCodeService.assignToMerchandise({
        id: this.editedAssignMerchandiseId,
        merchandise_id: this.editedAssignMerchandiseItem.merchandise_id,
      });
      this.closeForm();
      await this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    printUnclaimQrCode() {
      let routeData = this.$router.resolve({
        name: "printout-unclaim-qr-code",
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData(
      this.limit,
      this.search,
      this.currentPage,
      this.sort,
      this.order
    );
    anggotaService.getWithoutQrCode().then((resp) => {
      this.anggota = resp.data;
    });

    merchandiseService.getUnclaimed().then((resp) => {
      this.merchandise = resp.data;
    });
  },
};
</script>

<style scoped>
.qrcode {
  padding: 3rem 1.5rem;
}
</style>
