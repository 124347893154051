<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <button class="modal__close btn" @click="closeModal">
      <i class="bi bi-x"></i>
    </button>
    <span class="modal__title">{{ formTitle }}</span>
    <div class="modal__content py-2 px-2">
      <form @submit.prevent="submitForm" class="form">
        <div class="col-md-12">
          <label for="anggota_id" class="form-label">Anggota</label>
          <v-select
            v-model="editedItem.anggota_id"
            :options="anggota"
            :reduce="(anggota) => anggota.id"
            label="nama_lengkap"
            :class="{
              'is-invalid': $v.editedItem.anggota_id.$error,
            }"
            @search="fetchOptions"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :class="{
                  'is-invalid': $v.editedItem.anggota_id.$error,
                }"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
          <div v-if="$v.editedItem.anggota_id.$error" class="invalid-feedback">
            <span v-if="!$v.editedItem.anggota_id.required"
              >Anggota harus di isi.</span
            >
          </div>
        </div>
      </form>
    </div>
    <div class="modal__action mt-3">
      <button class="btn btn-primary mx-2" @click="submitForm">Terapkan</button>
      <button class="btn" @click="closeModal">Batal</button>
    </div>
  </vue-final-modal>
</template>

<script>
import { anggotaService } from "@/services";
import { required } from "vuelidate/lib/validators";
import { VueFinalModal } from "vue-final-modal";
export default {
  components: {
    VueFinalModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: Number,
      default: -1,
    },
    editedId: {
      type: Number,
      default: -1,
    },
    editedItem: {
      type: Object,
      required: true,
    },
    initialAnggota: {
      required: true,
    },
  },
  computed: {
    formTitle() {
      return "Terapkan ke Anggota";
    },
  },
  data() {
    return {
      anggota: this.initialAnggota,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("save");
      }
    },
    async fetchOptions(search, loading) {
      loading(true);
      const resp = await anggotaService.loadAll(search);
      this.anggota = resp.data;
      loading(false);
    },
  },
  validations: {
    editedItem: {
      anggota_id: {
        required,
      },
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 600px;
}
</style>
