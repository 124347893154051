<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" v-for="header in headers" :key="header">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!items.length">
        <td colspan="5" class="text-center">
          <span>Tidak ada data ditemukan.</span>
        </td>
      </tr>
      <TableItem
        v-for="item in items"
        :key="item.id"
        :data="item"
        :currentUser="currentUser"
        @edit="edit"
        @delete="confirmDelete"
        @assignToMember="assignToMember"
        @assignToMerchandise="assignToMerchandise"
        @unclaimQrCode="unclaimQrCode"
      />
    </tbody>
  </table>
</template>

<script>
import TableItem from "./TableItem.vue";
export default {
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableItem,
  },
  methods: {
    edit(data) {
      this.$emit("edit", data);
    },
    assignToMember(data) {
      this.$emit("assignToMember", data);
    },
    assignToMerchandise(data) {
      this.$emit("assignToMerchandise", data);
    },
    unclaimQrCode(data) {
      this.$emit("unclaimQrCode", data);
    },
    confirmDelete(id) {
      this.$emit("delete", id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
