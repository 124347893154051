<template>
  <tr>
    <td width="30%">{{ data.code }}</td>
    <td width="30%">
      {{
        data.anggota
          ? "KTA - "
          : data.Merchandise
          ? "MERCHANDISE - "
          : "VENDOR/BELUM DIGUNAKAN"
      }}
      {{ (data.anggota && data.anggota.nama_lengkap) || "" }}
      {{ (data.Merchandise && data.Merchandise.nama_produk) || "" }}
    </td>
    <td width="20%">
      <div class="btn-group" role="group" aria-label="Action menu">
        <button
          v-if="currentUser.role === 'Super User'"
          type="button"
          class="btn btn-secondary btn-sm"
          @click="showQrCode(data.code)"
        >
          <i class="bi bi-upc"></i> Lihat QR
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="assignToMember(data)"
          v-if="!data.anggota && !data.Merchandise"
        >
          <i class="bi bi-check2-all"></i> Terapkan ke Anggota
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="assignToMerchandise(data)"
          v-if="!data.Merchandise && !data.anggota"
        >
          <i class="bi bi-check2-all"></i> Terapkan ke Merchandise
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="assignToMember(data)"
          v-if="data.anggota"
        >
          <i class="bi bi-pencil"></i> Edit
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="unclaimQrCode(data)"
          v-if="data.anggota || data.Merchandise"
        >
          <i class="bi bi-eraser"></i> Lepaskan QR
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TableItem",
  props: {
    data: {
      type: Object,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  methods: {
    localDate(date) {
      return date.substring(0, 10);
    },
    edit(data) {
      this.$emit("edit", data);
    },
    assignToMember(data) {
      this.$emit("assignToMember", data);
    },
    assignToMerchandise(data) {
      this.$emit("assignToMerchandise", data);
    },
    unclaimQrCode(data) {
      this.$emit("unclaimQrCode", data);
    },
    confirmDelete(id) {
      this.$emit("delete", id);
    },
    showQrCode(qr_code) {
      let routeData = this.$router.resolve({
        name: "show-qr-code",
        query: { id: qr_code },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped></style>
